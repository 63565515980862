






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import DocumentsTabContent from "@/components/for-documents-view/DocumentsTabContent.vue";
import ContractDocumentsTabContent from "@/components/for-documents-view/ContractDocumentsTabContent.vue";

@Component({
  components: {
    Page,
    XTabs,
    DocumentsTabContent,
    ContractDocumentsTabContent,
  },
})
class Documents extends Vue {
  titles = ["Документы", "Документы договора"];

  components = [DocumentsTabContent, ContractDocumentsTabContent];

  mounted() {
  }
}

export default Documents;
